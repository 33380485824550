<template>
  <div class="box" style="background-color: #f8fcff;">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>一键报废</span>
        <el-steps class="steps-wrapper" align-center :active="active">
          <el-step description="填写车主信息" icon="el-icon-s-help"></el-step>
          <el-step description="填写车辆信息" icon="el-icon-s-help"></el-step>
          <el-step description="上传相关图片" icon="el-icon-s-help"></el-step>
          <el-step description="提交成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <div class="box">
        <el-form :rules="rules" ref="form" :model="form" label-width="100px">
          <el-form-item class="form-input" label="车辆大类" prop="parentType">
            <el-select v-model="form.parentType" placeholder="请选择" @change="parentTypeChange">
              <el-option v-for="item in parentTypeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item class="form-input" label="车辆小类" prop="type">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="行驶证正面">
            <div style="display: flex;">
              <div>
                <el-upload :accept="uploaderAccept" class="avatar-uploader" :action="url"
                  :multiple="false" :show-file-list="false" :before-upload="file => beforeAvatarUpload(file, 0)">
                  <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div class="photo-left">
                <img class="photo-right" src="../../../public/img/xingshi4.png" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="行驶证副面">
            <div style="display: flex;">
              <div>
                <el-upload :accept="uploaderAccept" class="avatar-uploader" :action="url"
                  :multiple="false" :show-file-list="false" :before-upload="file => beforeAvatarUpload(file, 1)">
                  <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div class="photo-left">
                <img class="photo-right" src="../../../public/img/xingshi1.png" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="整车照-车头">
            <div style="display: flex;">
              <div>
                <el-upload class="avatar-uploader" :accept="uploaderAccept" :action="url"
                  :multiple="false" :show-file-list="false" :before-upload="file => beforeAvatarUpload(file, 2)">
                  <img v-if="imageUrl3" :src="imageUrl3" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div class="photo-left">
                <img class="photo-right" src="/img/xingshi2.png" />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="整车照-车尾">
            <div style="display: flex;">
              <div>
                <el-upload :accept="uploaderAccept" class="avatar-uploader" :action="url"
                  :multiple="false" :show-file-list="false" :before-upload="file => beforeAvatarUpload(file, 3)">
                  <img v-if="imageUrl4" :src="imageUrl4" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div class="photo-left">
                <img class="photo-right" src="/img/xingshi3.png" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button class="submit-btn" @click="previousStep">
              上一步</el-button>
            <el-button class="submit-btn" @click="onSubmit">下一步
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  uploadScrapImage,
  getParentCarType,
  getCarType,
  scrapTwo,
} from "../../api/personalCenter";
export default {
  name: "fillInTheOrder",
  data() {
    return {
      imageUrl1: "",
      imageId1: "",
      imageUrl2: "",
      imageUrl3: "",
      imageUrl4: "",
      imageUrls: [],
      imageIds: [],
      active: 1,
      uploaderAccept: ".jpg,.jpeg",
      form: {
        parentType: "",
        type: "",
        detailType: "",
      },
      rules: {
        parentType: [
          {
            required: true,
            message: "请选择车辆大类",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择车辆小类",
            trigger: "blur",
          },
        ],
      },
      parentTypeList: [],
      typeList: [],
      url: "",
    };
  },
  async created() {
    const parentCarType = await getParentCarType();
    this.parentTypeList = parentCarType.result.data;
    this.url = process.env.VUE_APP_API_BASE_URL+'api/';
  },
  methods: {
    async beforeAvatarUpload(file, index) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      const fd = new FormData();
      fd.append("file", file);
      const res = await uploadScrapImage(fd);
      this.imageIds[index] = res.result.fileId;
      switch (index) {
        case 0:
          this.imageUrl1 = res.result.httpPath;
          this.imageId1 = res.result.fileId;
          break;
        case 1:
          this.imageUrl2 = res.result.httpPath;
          break;
        case 2:
          this.imageUrl3 = res.result.httpPath;
          break;
        case 3:
          this.imageUrl4 = res.result.httpPath;
          break;
        default:
          break;
      }
      return false;
    },
    async parentTypeChange() {
      const carType = await getCarType({ parentId: +this.form.parentType });
      this.typeList = carType.result.data;
    },
    previousStep() {
      this.$router.push({
        path: `/scrap/firstScrap?id=${this.$route.params.id}`,
      });
    },
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          // console.log(this.imageIds.length)
          // return false;
          if (this.imageIds.length<1) {
            this.$message.error("请至少上传一张照片");
            return false;
          }
          const requreCarToFiles = this.imageIds.map((item, index) => {
            return {
              PhotoType: index,
              FileDataId: item,
            };
          });
          const params = {
            CarTypeId: this.form.type,
            RequreCarToFiles: requreCarToFiles,
            Id: this.$route.params.id,
          };
          const res = await scrapTwo(params);
          window.sessionStorage.setItem("firstScrap", JSON.stringify({}));
          this.$router.push({
            path: `/scrap/thirdScrap`,
            query: {
              id: this.$route.params.id,
              imgId: this.imageId1,
              imgUrl: this.imageUrl1,
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 1200px; /* no */
  margin: 20px auto; /* no */
}
.clearfix {
  padding: 0 0 20px 0; /* no */
  font-size: 18px; /* no */
}
.form-input {
  width: 35%;
}
.steps-wrapper {
  width: 500px; /* no */
  float: right;
  /deep/.is-finish {
    color: #24a2aa !important;
    border-color: #24a2aa;
  }
}
.photo-left {
  width: 300px; /* no */
  height: 200px; /* no */
  margin-left: 20px; /* no */
}
.photo-right {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.submit-btn {
  background: #24a2aa;
  color: white;
  width: 180px; /* no */
  margin-right: 20px; /* no */
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #24a2aa;
  background: #24a2aa;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9; /* no */
  border-radius: 6px; /* no */
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px; /* no */
  color: #8c939d;
  width: 178px; /* no */
  height: 178px; /* no */
  line-height: 178px; /* no */
  text-align: center;
}

.avatar {
  width: 178px; /* no */
  height: 178px; /* no */
  display: block;
}

/deep/.el-upload {
  background-color: #eeeeee;
  width: 300px; /* no */
  height: 201.79px; /* no */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
